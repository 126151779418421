import { EContainerVariant, toImageProps, Container } from '@front/shared/ds';
import { Media } from '@shared/master-types';
import Image, { ImageProps } from 'next/image';
import React, { useRef, useEffect } from 'react';

export type TCustomImagesRow = {
  customType: 'system-overview';
  images: {
    image: string | Media;
    id?: string;
  }[];
  id?: string;
  blockName?: string;
  blockType: 'custom-images';
};

type TCustomSpaceCenterImageRowProps = {
  row: TCustomImagesRow;
};

export const CustomSpaceCenterImageRow: React.FC<
  TCustomSpaceCenterImageRowProps
> = ({ row }) => {
  const imageWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = imageWrapperRef?.current;

    if (!element) {
      return;
    }

    const observer = new ResizeObserver(() => {
      element.scrollLeft =
        window.innerWidth < element.scrollWidth
          ? (element.scrollWidth - window.innerWidth) / 2
          : 0;
    });

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, []);

  if (!row.images.length) {
    return null;
  }

  const nextImageProps: ImageProps | null = toImageProps(row.images[0].image);

  if (!nextImageProps) {
    return null;
  }

  return (
    <Container variant={EContainerVariant.Default} isPadding={false}>
      <div
        ref={imageWrapperRef}
        className='scrollbar-hidden flex overflow-auto'
      >
        <Image
          className='m-auto max-w-none'
          {...nextImageProps}
          alt={nextImageProps.alt}
        />
      </div>
    </Container>
  );
};
